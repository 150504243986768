import { AnimatePresence, motion } from "framer-motion";
import {
  dialogAnimation,
  dialogFloatUpAnimation,
  loginAnimation,
} from "../utils/animation";
import { Button, Input, Logo, Text, Title } from "@tigris/mesokit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { defaultFormField, emailSchema, passwordSchema } from "@tigris/common";
import {
  useState,
  useCallback,
  FormEventHandler,
  useRef,
  ChangeEventHandler,
  useEffect,
} from "react";
import {
  resetSessionIdentifier,
  resolvePerformEmailPasswordLogin,
  resolveSession,
  resolveUser,
  setBearerToken,
} from "../api";
import { toast } from "sonner";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../hooks/useAppContext";
import { AccountAppRoutes, PARTNER_ID } from "../utils/constants";
import { Session, FormField } from "../types";
import { ErrorMessages } from "../utils/errorMessages";
import { Amplitude } from "../telemetry";
import { Dialog, DialogPanel } from "@headlessui/react";

const SignUpAnimation = {
  initial: { opacity: 0, y: 148 },
  exit: { opacity: 0, y: 148 },
  animate: { opacity: 1, y: 0 },
};

type FormState = {
  isValid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  fields: Record<"email" | "password", FormField>;
};

const defaultFormState: FormState = {
  isValid: false,
  isDirty: false,
  isTouched: false,
  fields: { email: defaultFormField(""), password: defaultFormField("") },
};

const FORM_ID = "EmailPasswordLogin";
const TOAST_ID = FORM_ID;
const ALLOWED_ACCOUNT_APP_USER_STATUSES = [
  "active",
  "in_review",
  "in_review_blocked",
];

export const LoginEmailPassword = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState<FormState>(defaultFormState);
  const [isLoading, setIsLoading] = useState(false);
  const { session, setSession } = useAppContext();
  const emailInput = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();

  // If we are logging out a user because they have been frozen, we need to present alternative UI
  const isFrozenUser = searchParams.get("frozenUser") === "true";

  const handleError = useCallback((message: React.ReactNode) => {
    toast.error(message, { id: TOAST_ID });
    setIsLoading(false);
  }, []);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      setIsLoading(true);

      const sessionResult = await resolveSession({
        input: { partnerId: PARTNER_ID },
      });

      if (sessionResult.isErr()) {
        handleError(ErrorMessages.emailPasswordLogin.UNABLE_TO_LOGIN);
        return;
      }

      const newSession: Session = {
        id: sessionResult.value.id,
        token: sessionResult.value.token,
        riskSessionKey: sessionResult.value.riskSession.sessionKey,
      };

      setBearerToken(sessionResult.value.token, navigate);
      setSession(newSession);

      const performEmailPasswordLoginResult =
        await resolvePerformEmailPasswordLogin({
          input: {
            riskSessionKey: sessionResult.value.riskSession.sessionKey,
            email: formState.fields.email.value,
            password: formState.fields.password.value,
          },
        });

      if (performEmailPasswordLoginResult.isErr()) {
        handleError(ErrorMessages.emailPasswordLogin.UNABLE_TO_LOGIN);
        setTimeout(() => emailInput.current?.focus(), 10);
        return;
      }

      if (
        !ALLOWED_ACCOUNT_APP_USER_STATUSES.includes(
          performEmailPasswordLoginResult.value.userStatus,
        )
      ) {
        handleError(
          <div>
            It looks like you don&apos;t have a Meso account or we haven&apos;t
            seen this wallet before.
            <div>
              Visit{" "}
              <a href="https://meso.cash" target="_blank" rel="noreferrer">
                meso.cash
              </a>{" "}
              to get started!
            </div>
          </div>,
        );
        return;
      }

      if (performEmailPasswordLoginResult.value.needsTwoFactor) {
        navigate(AccountAppRoutes.LOGIN_2FA);
      } else {
        const userResult = await resolveUser();

        if (userResult.isOk()) {
          setSession({ ...newSession, user: userResult.value });
          navigate(
            userResult.value.status === "active"
              ? AccountAppRoutes.TRANSFERS
              : AccountAppRoutes.PROFILE_SETTINGS,
          );
        } else {
          handleError(ErrorMessages.emailPasswordLogin.UNABLE_TO_LOGIN);
          return;
        }
      }
    },
    [
      formState.fields.email.value,
      formState.fields.password.value,
      handleError,
      navigate,
      setSession,
    ],
  );

  const handleInputChange = useCallback<
    (field: keyof FormState["fields"]) => ChangeEventHandler<HTMLInputElement>
  >(
    (field) => (event) => {
      const newValue = event.target.value;

      setFormState((previousState) => {
        const isDirty =
          previousState.fields[field].isTouched ||
          newValue !== previousState.fields[field].value;

        var isValid = false;
        if (field === "email") {
          const result = emailSchema.safeParse(newValue);
          isValid = result.success;
        } else if (field === "password") {
          const result = passwordSchema.safeParse(newValue);
          isValid = result.success;
        }

        if (!isValid) {
          Amplitude.track("Form Input Invalid", {
            formId: FORM_ID,
            inputId: field,
          });
        }

        return {
          ...previousState,
          isDirty: previousState.isDirty || isDirty,
          isValid:
            isValid &&
            Object.entries(previousState.fields)
              .filter(([key, _]) => key !== field)
              .every(([_, { isValid }]) => isValid),
          fields: {
            ...previousState.fields,
            [field]: {
              ...previousState.fields[field],
              value: newValue,
              isValid,
              isDirty,
            },
          },
        };
      });
    },
    [],
  );

  const handleBlur = useCallback(
    (field: keyof FormState["fields"]) => () =>
      setFormState((previousState) => ({
        ...previousState,
        isTouched: true,
        isValid: Object.values(previousState.fields).every(
          (field) => field.isValid,
        ),
        isDirty: Object.values(previousState.fields).every(
          (field) => field.isDirty,
        ),
        fields: {
          ...previousState.fields,
          [field]: {
            ...previousState.fields[field],
            isTouched: true,
          },
        },
      })),
    [],
  );

  const renderFieldAsValid = useCallback(
    (fieldKey: keyof FormState["fields"]): boolean => {
      const field = formState.fields[fieldKey];
      if (!field.isTouched || field.isValid) {
        return true;
      }

      if (field.isTouched && field.isDirty) {
        return field.isValid;
      }

      return true;
    },
    [formState.fields],
  );

  // Navigate to transfers if already logged in or check for query params that are used to signal
  // special logged-out states such as a frozen user. In those cases, perform the background logout here.
  useEffect(() => {
    // Look for query params
    if (isFrozenUser) {
      setSession(undefined);
      resetSessionIdentifier();
    } else if (session?.user) {
      navigate(AccountAppRoutes.TRANSFERS);
    }
  }, [isFrozenUser, navigate, searchParams, session?.user, setSession]);

  if (isFrozenUser) {
    return (
      <div className="flex justify-center">
        <AnimatePresence mode="wait">
          <Dialog
            as={motion.div}
            className="relative z-50 opacity-0"
            open={true}
            onClose={() => {}}
            static
            key="Dialog"
            {...dialogAnimation}
          >
            <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
              <DialogPanel
                className="rounded-ts-card mx-auto flex max-w-sm flex-col gap-4 bg-white px-6 py-10 shadow-lg dark:bg-neutral-700"
                as={motion.div}
                {...dialogFloatUpAnimation}
                key="Panel"
                data-testid="DialogPanel"
              >
                <div className="flex items-center gap-4">
                  <Link
                    to={AccountAppRoutes.ROOT}
                    className="focus focus:outline-none"
                  >
                    <Logo showText={false} size="md" />
                  </Link>
                  <Title.Medium bold>Account Under Review</Title.Medium>
                </div>
                <Text>
                  Your account is currently under review. A customer service
                  ticket has been created, and a copy sent to your email.
                </Text>
                <Text>
                  If you have not received an email from us or your email
                  address needs to be corrected, please contact us directly at{" "}
                  <a
                    className="font-bold opacity-80 transition-opacity duration-100 hover:opacity-100"
                    href="mailto:support@meso.network"
                  >
                    support@meso.network
                  </a>
                  .
                </Text>
              </DialogPanel>
            </div>
          </Dialog>
        </AnimatePresence>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <AnimatePresence mode="wait">
        <motion.div
          key="login-form"
          className="flex flex-col items-center justify-center gap-4"
          {...loginAnimation}
        >
          <section className="flex flex-col items-center gap-1">
            <Logo showText={false} size="md" className="mb-2" />
            <Title.Medium className="font-bold">Log In</Title.Medium>
            <Text>Log in to your Meso account.</Text>
          </section>
          <form
            className="flex flex-col gap-4"
            id={FORM_ID}
            name={FORM_ID}
            data-testid={FORM_ID}
            onSubmit={handleSubmit}
          >
            <section className="flex w-80 flex-col gap-2">
              <Input
                autoComplete="username"
                autoFocus
                data-testid={`${FORM_ID}:email`}
                disabled={isLoading}
                id="email"
                isValid={renderFieldAsValid("email")}
                name="email"
                onBlur={handleBlur("email")}
                onChange={handleInputChange("email")}
                placeholder="Email"
                ref={emailInput}
                type="email"
                value={formState.fields.email.value}
              />
              <Input
                autoComplete="password"
                data-testid={`${FORM_ID}:password`}
                disabled={isLoading}
                id="password"
                isValid={renderFieldAsValid("password")}
                name="password"
                onBlur={handleBlur("password")}
                onChange={handleInputChange("password")}
                placeholder="Password"
                type="password"
                value={formState.fields.password.value}
              />
            </section>
            <section className="flex flex-col gap-2 text-center">
              <AnimatePresence initial={false}>
                <Button
                  type="submit"
                  data-testid={`${FORM_ID}:submit`}
                  disabled={isLoading || !formState.isValid}
                  isLoading={isLoading}
                >
                  Log In
                </Button>
              </AnimatePresence>
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate(AccountAppRoutes.REQUEST_PASSWORD_RESET);
                }}
              >
                <Text className="font-medium text-neutral-500">
                  Forgot Password?
                </Text>
              </div>
            </section>
          </form>
        </motion.div>
      </AnimatePresence>
      <AnimatePresence mode="wait">
        <motion.div
          key="signup-cta"
          {...SignUpAnimation}
          className="absolute bottom-0 mb-5 md:mb-8"
        >
          <section className="flex flex-col items-center gap-4 rounded-2xl bg-white p-4 shadow-2xl dark:bg-neutral-700">
            <div className="flex flex-col gap-1">
              <div className="flex items-center justify-center gap-1">
                <FontAwesomeIcon
                  icon={icon({ name: "hand-wave", style: "regular" })}
                  className="text-[#09D389]"
                />
                <Title.Small className="text-base font-semibold">
                  New to Meso?
                </Title.Small>
              </div>
              <Text className="text-ts-subtle text-center">
                Check out meso.cash to try it out and sign up.
              </Text>
            </div>
            <Button
              data-testid={`${FORM_ID}:getStarted`}
              containerClassName="block"
              className="h-8 rounded-3xl px-3 text-xs font-bold"
              primary={false}
              onClick={() => window.open("https://meso.cash", "_blank")}
            >
              Get Started with Meso
              <FontAwesomeIcon
                icon={icon({
                  name: "arrow-up-right-from-square",
                  style: "solid",
                })}
                className="pl-1 opacity-50 dark:opacity-100"
              />
            </Button>
          </section>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
